<template>
    <el-divider></el-divider>
    <el-form ref="formRef" :model="formodel" label-width="100px"> 
        <el-form-item label="钥匙码">
            <el-input v-model="formodel.keycode" placeholder="请输入钥匙码" />
        </el-form-item>
        <el-form-item label="次数">
            <el-input  v-model="formodel.count" placeholder="请输入次数"  />
        </el-form-item>
        <el-form-item>
            <el-button type="info" @click="clearForm">清空</el-button>
            <el-button type="primary" @click="onSubmit">保存</el-button>
        </el-form-item>
    </el-form>
</template>
<script>
export default {
    data() {
        return {
            formodel: {
                keycode: '',//名称
                count: 0 //状态
            }
        }
    },
    created() {
       
    },
    methods: {
        onSubmit() {
            const _this = this;
            this.$https.post('api/Admin/KeyCode/insertOrUpdate', _this.formodel).then(res => {
                if (res.success) { 
                    _this.clearForm()
                    _this.$emit('closeDialog');
                }
            })
        },
        //清空
        clearForm() {
          
        }
    }
}
</script>